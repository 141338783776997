<template>
    <div class="lct-content">
        <vip-menu @onTagsActive="onTagsActive"></vip-menu>
        <div class="lct-main">
            <vip-course-item v-for="(item,index) in lists" :item="item" @click.native="toPath(`/courseVip/${item.cid}/detail`)" :key="item.cid"></vip-course-item>

            <no-data v-if="lists.length<=0"/>
        </div>
        <div class="pagination" v-if="lists.length>0">
            <el-pagination
                    background
                    layout="prev, pager, next, jumper"
					:current-page="current"
                    :page-count="total"
                    @current-change="currentChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import Search from '@/utils/search'
    export default {
        data() {
            return {
                current: 1,
                total: 0,
                tags: {},
                keyword: this.$route.query.keyword,
                lists: []
            }
        },
        beforeDestroy() {
            Search.$off("search");
        },
        mounted() {
            Search.$on('search',data => {
                this.keyword = data.keyword;
                this.page = 1;
                this.getList();
            })
            this.getList();
        },
        methods: {
            onTagsActive(tags) {
                this.tags = tags;
                this.current = 1;
                this.getList();
            },
            getList() {
                this.$axios.post(this.apiUrl.courseListChargeList, {
					...this.tags,
                    keyword: this.keyword,
                    page: this.current,
                    pagesize: 20
                }).then(res => {
                    this.lists = res.data.lists;
                    this.total = res.data.allpage;
                })
            },
            currentChange(e) {
                document.documentElement.scrollTop = 0
                this.current = e;
                this.getList();
            },
            toPath(url) {
                this.$router.push({
                    path: url
                })
            }
        }
    }
</script>
